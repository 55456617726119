body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
.container {
  display: grid;
  place-content: center;
  min-height: 100vh;
  width: 100vw;
  background-image: url("../public/bg2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  position: relative;
}

.content {
  color: #000;
  display: grid;
  place-content: center;
  text-align: center;
  color: #e71919;
}

.logo {
  width: 80%;
  margin-inline: auto;
}

.btns {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
}

 a {
  text-decoration: none;
  width: 170px;
  padding-block: 10px;
  font-size: 18px;
  background-color: transparent;
  border: 2.5px solid #269326;
  color: #e71919;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.2s;
}

button:hover {
  background-color: rgba(228, 227, 215, 0.5);
}

.play-btn {
  position: absolute;
  width: 40px;
  bottom: 10px;
  right: 10px;
  z-index: 1;
  cursor: pointer;
}

.modal{
  width: 200px;
  height: 150px;
}
.modal_Conatiner{
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}
.modal{
  background-color: white;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 10px 6px 15px black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  animation: all 1s ease-in-out;
}
.popup{
  width: 400px;
  height: 200px;
}

.downloadPopup{
  width: 800px;
  height: 400px;
}


button{
  padding: 10px 20px;
  border-radius: 10px;
  background-color: lightblue;
  outline: none;
  border: none;
  font-size: 1rem;
}
.downloadBtn{
  width: 250px;
  margin-bottom: 10px;
}